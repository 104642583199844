import React, { useState, useEffect } from 'react'
import { Container, Button } from 'react-bootstrap'
import Playgame from './Playgame'
import { Link } from 'react-router-dom'
import playgamedata from './Playgamedata'
import axios from "axios";
import { useNavigate } from 'react-router-dom';


export default function Play() {
  const [users, setUsers] = useState([]);
  const [usersdata, setUsersdata] = useState([]);
  const [loading1, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleReload = () => {
    window.scrollTo(0, 0);

  };


  useEffect(() => {
    loaduser();
  }, [])

  const loaduser = async () => {
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");
    setLoading(true);

    // alert(user_id);
    let url = (`${process.env.REACT_APP_API_URL}/POM_all_market.php`);
    // console.warn(url);
    const formData = new FormData();
    formData.append('app_id', process.env.REACT_APP_API_ID);
    formData.append('user_id', user_id);
    formData.append('dev_id', dev_id);
    var config = {
      method: 'POST',
      url: url,
      body: formData,
    };
    axios.post(url, formData, config)
      .then(function (response) {
        const res = JSON.stringify(response.data.data);
        const objectRes = JSON.parse(res);
        setUsers(objectRes);
        console.warn(objectRes);

      })
      .finally(() => {
        // setTimeout(() => {
        setLoading(false);
        // }, 1000);
      });
  }

  const handleClick = (id, name) => {
 
    // localStorage.setItem('name1',users.name);
    // console.warn(users.name);
    // window.location.href = `/Playgame?id=${id}&name=${name}`;
    navigate(`/Playgame?id=${id}&name=${name}`);
    setTimeout(() => {
      window.location.reload();
    }, 0);
  };
  return (
    <>
      <section id="play" className='margin-bottom-88'>

        <div className="playsection">
          <Container>
            <div className="d-flex justify-content-center">
              <p className='mb-0 mt-2'> All Games</p>
              {/* <button className='playgamess'>
    my Games
    </button> */}
            </div>
            <div className="bg-white border-radius-8">
              <div className="row">
                {users && users.map((user) => (
                  <div className="col-md-12">
                    <div className="gamebg market " >
                      <div className="d-flex justify-content-between gameplaybattle">
                        <div className="marketnamepaly marketnamelist1">
                          <h3 className='animate-charcter animationtittle markettitlename'>{user.name} </h3>
                          <div className="d-flex align-items-center">
                            <i class="clock bi bi-clock-fill"></i>
                            <p className='animate-charcter'>{user.open_time}</p> -
                            <p className='animate-charcter'>{user.time}</p>
                          </div>
                        </div>



                        <div className="d-flex align-items-center linkgamesforplay">

                          {user.is_play == 1 ?
                            // <Button onClick={() => { handleClick(user.id, user.name); handleReload(); }} className='playgames refer-button marketnamelist play-button'><h3 className='fs-13 animationtittle markettitlenam'>Play Games</h3></Button>
                            <Button onClick={() => { handleClick(user.id, user.name); handleReload(); }} className='playgames refer-button marketnamelist play-button'><h3 className='fs-13 animationtittle markettitlenam'>Play Games</h3></Button>
                            :
                            <Button className='timeout'>Time Out</Button>
                          }
                        </div>

                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Container>
        </div>
        {loading1 && (
          // <>
          // <div className="loader_roomcode">
          //   <img src={loader} alt="loader" />
          // </div>
          // </>
          <div className="spinner-wrapper">
            <div className="loadernew2"></div>
          </div>
        )}
      </section>




    </>
  )
}
